import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/register/Login";
import "./App.css";
import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useAuth } from "./components/AuthContext";
import Appointments1 from "./pages/appointments/Appointments1";
import axios from "axios";
import ResponsiveDrawer from "./components/Drawer.tsx";
import Patients from "../src/pages/Patients.tsx";
import Users from "./pages/Users.tsx";
import EditAppointment from "./pages/EditAppointment";

function App() {
	const [user, setUser] = useState({});
	const { checkToken } = useAuth();
	const token = checkToken();
	const token1 = JSON.parse(localStorage.getItem("tokens"));
	const Navigate1 = useNavigate();
	axios.defaults.baseURL = "http://154.49.136.233:8001/api/";
	axios.defaults.headers.common["Content-Type"] = "application/json";
	axios.defaults.headers.common["Accept"] = "application/json";
	axios.defaults.headers.common["Authorization"] = `Bearer ${token1?.accessToken}`;

	useEffect(() => {
		const my_info = () => {
			if (checkToken()) {
				axios
					.get("account/my_info")
					.then((res) => {
						setUser(res.data);
					})
					.catch((err) => {
						if (err.message === "Network Error") {
							localStorage.removeItem("tokens");
							localStorage.removeItem("exp");
							Navigate1("/login");
						}
					});
			}
		};
		my_info();
	}, [checkToken, Navigate1]);

	return (
		<Box className="app">
			{token ? null: <Navigate to="/login" />}
			<Routes>
				<Route
					path="/"
					element={
						<ResponsiveDrawer user={user}>
							<Appointments1 user={user} />
						</ResponsiveDrawer>
					}
				/>
				<Route
					path="/patients"
					element={
						<ResponsiveDrawer user={user}>
							<Patients user={user} />
						</ResponsiveDrawer>
					}
				/>
				<Route
					path="/users"
					element={
						<ResponsiveDrawer user={user}>
							<Users user={user} />
						</ResponsiveDrawer>
					}
				/>
				<Route
					path={`/editAppointment/:id`} 
					element={
						<ResponsiveDrawer user={user}>
							<EditAppointment user={user} />
						</ResponsiveDrawer>
					}
				/>
				<Route path="/login" element={<Login />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</Box>
	);
}

export default App;
