// Import necessary dependencies
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface AddPatientProps {
    id: number | null;
    setSelectedRowId : Function | null;
}

interface FadeProps {
    children: React.ReactElement;
    in?: boolean;
    onClick?: any;
    onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
    onExited?: (node: HTMLElement, isAppearing: boolean) => void;
    ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>((props, ref) => {
    const { children, in: open, onClick, onEnter, onExited, ownerState, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null as any, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null as any, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    textAlign: "center" as "center",
};

export default function AddPatient({ id ,setSelectedRowId }: AddPatientProps) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState("");
    const [name, setName] = React.useState("");
    const [age, setAge] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [address, setAddress] = React.useState("");
    const navigate = useNavigate();

    React.useEffect(() => {
        if (id) {
            const config = {
                method: "get",
                url: `clinic_controller/get_patient_by_id?patient_id=${id}`,
            };
            axios(config)
                .then((response) => {
                    setName(response.data.name);
                    setAge(response.data.age);
                    setPhone(response.data.phone);
                    setAddress(response.data.address);
                    setLoading(false);
                })
                .catch((error) => {
                    setError("حدث خطأ ما");
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false);
        if(setSelectedRowId)
        setSelectedRowId(null);}
    const handleAdd = () => {
        let url = "";
        let method = "";
        if (id) {
            url = `clinic_controller/edit_patient?patient_id=${id}`;
            method = "put";
        } else {
            url = "clinic_controller/add_patient";
            method = "post";
        }
        const config = {
            method: method,
            url: url,
            data: {
                name: name,
                age: age,
                phone: phone,
                address: address,
                is_active:true,
            },
        };
        axios(config)
            .then((response) => {
                setName(response.data.name);
                setAge(response.data.age);
                setPhone(response.data.phone);
                setAddress(response.data.address);
                setLoading(false);
                handleClose();
                navigate(0);
            })
            .catch((error) => {
                setError("حدث خطأ ما");
                setLoading(false);
            });
    };

    return (
        <div>{
            !id ? ( <Button
                variant="contained"
                onClick={handleOpen}
                sx={{
                    borderRadius: "100%",
                    width: "64px",
                    height: "64px",
                    color: "#fff",
                    backgroundColor: "#bf3272",
                    "&:hover": {
                        backgroundColor: "#d56195",
                        color: "black",
                    },
                }}
            >
                <AddIcon />
            </Button>
            ) : (
                setTimeout(() => {
                    setOpen(true);
                }
                , 1)
            )

            }
            
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
            >
                <Fade in={open}>
                    {loading ? (
                        <h1>loading</h1>
                    ) : (
                        <Box sx={style}>
                            {error && <h1>{error}</h1>}
                            <Typography
                                id="spring-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{ fontWeight: "600", fontSize: "20px" }}
                            >
                                {id ? "تعديل بيانات المريض" : "اضافة مريض جديد"}
                            </Typography>
                            <TextField
                                id="name"
                                label="الاسم"
                                variant="standard"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                sx={{ marginBottom: "10px" }}
                            />
                            <TextField
                                id="age"
                                label="العمر"
                                variant="standard"
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                                sx={{ marginBottom: "10px" }}
                            />
                            <TextField
                                id="phone"
                                label="الهاتف"
                                variant="standard"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                sx={{ marginBottom: "10px" }}
                            />
                            <TextField
                                id="address"
                                label="العنوان"
                                variant="standard"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                sx={{ marginBottom: "10px" }}
                            />
                            <br />
                            <Button
                                onClick={handleAdd}
                                variant="contained"
                                sx={{ backgroundColor: "#bf3272", color: "#fff", margin: "10px" }}
                            >
                                {id ? "تعديل" : "اضافة"}
                            </Button>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                sx={{ backgroundColor: "#bf3272", color: "#fff", margin: "10px" }}
                            >
                                الغاء
                            </Button>
                        </Box>
                    )}
                </Fade>
            </Modal>
        </div>
    );
}
