import image from "../../imgs/logo.png";

function ImageComponent() {
  return (
    <>
      <img
        style={{
          width: "200px",
          height: "240px",
          position: "absolute",
          top: "55px",
          objectFit: "contain",
        }}
        src={image}
        alt="logo"
        loading="lazy"
      />
    </>
  );
}

export default ImageComponent;
