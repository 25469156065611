/* eslint-disable no-throw-literal */
// AuthContext.js
import { createContext, useContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isLogin, setIsLogin] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [patientsNames , setPatientsNames ] = useState(null);

  const login = () => {};

  const logout = () => {
    localStorage.removeItem("tokens");
    localStorage.removeItem("exp");
  };

  function checkToken() {
    try {
      const tokens = JSON.parse(localStorage.getItem("tokens"));
      const exp = +localStorage.getItem("exp");
      const decodedData = jwtDecode(tokens.accessToken);

      if (!tokens) throw "No token";

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${tokens.accessToken}`;

      if (!exp) throw "no expire date";
      if (exp < Date.now() / 1000) throw "expire token";

      return decodedData;
    } catch (err) {
      localStorage.removeItem("tokens");
      localStorage.removeItem("exp");
      return null;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        error,
        setError,
        loading,
        setLoading,
        checkToken,
        isLogin,
        setIsLogin,
        patientId,
        setPatientId,
        userId,
        setUserId,
        isSuperUser,
        setIsSuperUser,
        patientsNames,
        setPatientsNames
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
