import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AutoComplete1 from './AutoComplete';
import GetDoctors from './GetDoctors';
import Grid from '@mui/material/Grid';

const AddAppointment = ({ user }) => {
  const navigate = useNavigate();
  const [date, setDate] = useState('');
  const [doctor, setDoctor] = useState('');
  const [note, setNote] = useState('');
  const [patients, setPatients] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const addAppointment = () => {
    const config = {
      method: 'post',
      url: 'clinic_controller/add_appointment',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: {
        date: date,
        note: note,
        patient: patients.id,
        doctor: doctor.id,
      },
    };
    axios(config)
      .then((response) => {
        setOpenModal(false);
        setOpenSnackbar(true);
        navigate(0);
      })
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '10px',
      }}
    >
      <Button
        variant="contained"
        onClick={handleModalOpen}
        sx={{
          borderRadius: '50%',
          width: '64px',
          height: '64px',
          color: '#fff',
          backgroundColor: '#bf3272',
          '&:hover': {
            backgroundColor: '#d56195',
            color: 'black',
          },
        }}
      >
        <AddIcon />
      </Button>

      <Dialog open={openModal} onClose={handleModalClose} maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            width: '100%',
          }}
        >
          <TextField
            label="التاريخ"
            variant="outlined"
            type="date"
            sx={{ marginBottom: '20px', width: '100%' }}
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <Box sx={{ width: '100%', marginBottom: '20px' }}>
            <AutoComplete1 setPatient={setPatients} />
          </Box>

          {(user.is_superuser || (user.user_permissions && user.user_permissions.some(permission => permission.permission === "سكرتير"))) && (
            <Box sx={{ width: '100%', marginBottom: '20px' }}>
              <GetDoctors setDoctor={setDoctor} />
            </Box>
          )}

          <TextField
            label="ملاحظات"
            variant="outlined"
            sx={{ marginBottom: '20px', width: '100%' }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                sx={{ width: '100%', marginBottom: '10px', backgroundColor: '#bf3272',fontSize:"13px" }}
                onClick={addAppointment}
              >
                اضافة موعد
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                sx={{ width: '100%', backgroundColor: '#7f7f7f',fontSize:"13px" }}
                onClick={handleModalClose}
              >
                الغاء
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          تمت اضافة الموعد بنجاح
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddAppointment;
