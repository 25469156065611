import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

function Row(props) {
	const { row, onDelete, onEdit } = props;
	const [open, setOpen] = React.useState(false);
 
	return (
	   <React.Fragment>
		  <TableRow
			 sx={{
				"& > *": { borderBottom: "unset" },
				backgroundColor: row.is_active ? "#e7ffe8" : "#ffcfcf",
			 }}
		  >
			 <TableCell>
				<IconButton
				   aria-label="expand row"
				   size="small"
				   onClick={() => setOpen(!open)}
				>
				   {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			 </TableCell>
			 <TableCell align="center" component="th" scope="row">
				{row.patient}
			 </TableCell>
			 <TableCell align="center">{row.date}</TableCell>
			 <TableCell align="center">{row.note}</TableCell>
			 <TableCell align="center">{row.doctor}</TableCell>
			 <TableCell>
				<IconButton onClick={() => onDelete(row.id)}>
				   <DeleteIcon />
				</IconButton>
				<IconButton onClick={() => onEdit(row.id)}>
				   <EditIcon />
				</IconButton>
			 </TableCell>
		  </TableRow>
		  <TableRow>
			 <TableCell colSpan={6}>
				<Collapse in={open} timeout="auto" unmountOnExit>
				   <Box sx={{ margin: 1 }}>
					  <Typography variant="h6" gutterBottom component="div">
						 التفاصيل
					  </Typography>
					  <Table size="small" aria-label="purchases">
						 <TableHead>
							<TableRow>
							   <TableCell align="center">التشخيص</TableCell>
							   <TableCell align="center">العلاجات</TableCell>
							   <TableCell align="center">الدفع</TableCell>
							</TableRow>
						 </TableHead>
						 <TableBody>
							<TableRow>
							   <TableCell align="center">{row.details?.diagnosis || 'لايوجد تشخيص'}</TableCell>
							{row.details.length > 0 ? (
								row.details.map((detail, index) => (
									 <TableCell align="center" key={index}>
										{detail.treatments}
									 </TableCell>
							   ))
							   ) : (
								  <TableCell align="center">لايوجد علاجات</TableCell>
							)}
							   <TableCell align="center">{row.payment}</TableCell>
							</TableRow>
						 </TableBody>
					  </Table>
				   </Box>
				</Collapse>
			 </TableCell>
		  </TableRow>
	   </React.Fragment>
	);
 }
 
 
 Row.propTypes = {
	row: PropTypes.shape({
	   id: PropTypes.number.isRequired,
	   date: PropTypes.string.isRequired,
	   note: PropTypes.string.isRequired,
	   doctor: PropTypes.string.isRequired,
	   patient: PropTypes.string.isRequired,
	   is_active: PropTypes.bool.isRequired,
	   details: PropTypes.arrayOf(
		  PropTypes.shape({
			 diagnosis: PropTypes.string || "لايوجد تقرير",
			 treatments: PropTypes.string.isRequired,
			 payment: PropTypes.number || 0,
		  })
	   ).isRequired,
	}).isRequired,
 };
 

export default function AppointmentsTable({ appointments, setAppointments }) {
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [selectedAppointmentId, setSelectedAppointmentId] =
		React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const Navigate = useNavigate();

	const handleDelete = () => {
		const config = {
			method: "delete",
			url: `clinic_controller/delete_appointment?appointment_id=${selectedAppointmentId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		axios(config)
			.then((res) => {
				setMessage("تم حذف الموعد بنجاح");
				setOpen(true);
				const filteredAppointments = appointments.data.filter(
					(appointment) => appointment.id !== selectedAppointmentId
				);
				setAppointments({ data: filteredAppointments });
				setOpenDeleteDialog(false);
			})
			.catch((err) => {
				console.error("Error during delete appointment:", err.message);
				if (err.response.status === 401) {
					localStorage.removeItem("tokens");
					localStorage.removeItem("exp");
					Navigate("/login");
				}
				if (err.message === "Network Error") {
					localStorage.removeItem("tokens");
					localStorage.removeItem("exp");
					Navigate("/login");
				}
			});
	};

	const convertData = (data) => {
		return data.map((appointment) => {
			const doctorName = appointment.doctor
				? appointment.doctor.full_name
				: "Unknown Doctor";
			const patientName = appointment.patient
				? appointment.patient.name
				: "Unknown Patient";

			return {
				id: appointment.id,
				date: appointment.date,
				note: appointment.note,
				doctor: doctorName,
				patient: patientName,
				payment: appointment.payment ? appointment.payment.amount : 0,
				is_active: appointment.is_active,
				diagnosis: appointment.report
					? appointment.report.report
					: "لايوجد تقرير",
				details: Array.isArray(appointment.prescription)
					? appointment.prescription.map((prescription) => ({
							treatments: prescription.medicine.name,
					  }))
					: "لايوجد علاجات",
			};
		});
	};

	const rows = convertData(appointments.data);

	const handleDeleteClick = (id) => {
		setSelectedAppointmentId(id);
		setOpenDeleteDialog(true);
	};

	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell align="center">المريض</TableCell>
						<TableCell align="center">التاريخ</TableCell>
						<TableCell align="center">الملاحظات</TableCell>
						<TableCell align="center">الطبيب</TableCell>
						<TableCell sx={{ width: "112px" }} align="center">
							التحكم
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<Row
							key={row.id}
							row={row}
							onDelete={() => handleDeleteClick(row.id)}
							onEdit={() => Navigate(`/editAppointment/${row.id}`)}
						/>
					))}
				</TableBody>
			</Table>
			<Dialog
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<Typography variant="h6" gutterBottom component="div">
					هل انت متأكد من حذف الموعد؟
				</Typography>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-around",
						padding: "20px",
					}}
				>
					<Button variant="contained" color="error" onClick={handleDelete}>
						نعم
					</Button>
					<Button
						variant="contained"
						color="success"
						onClick={() => setOpenDeleteDialog(false)}
					>
						لا
					</Button>
				</Box>
			</Dialog>
			<Snackbar
				open={open}
				autoHideDuration={5000}
				onClose={(event, reason) => {
					if (reason === "clickaway") {
						return;
					}

					setOpen(false);
				}}
				message={message}
			/>
		</TableContainer>
	);
}
