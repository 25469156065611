import { Box, Container } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import TableUsers from "../components/TableUsers.tsx";
import React from "react";
import AddUser from "../components/AddUser.tsx";

interface Column {
	id:
		| "id"
		| "full_name"
		| "phone_number"
		| "location"
		| "is_blocked"
		| "note"
		| "user_permissions";
	label: string;
	minWidth?: number;
	align?: "right";
	format?: (value: number) => string;
}

const columns: readonly Column[] = [
	{ id: "id", label: "التسلسل", minWidth: 50 },
	{ id: "full_name", label: "الاسم", minWidth: 170 },
	{ id: "phone_number", label: "الهاتف", minWidth: 120 },
	{ id: "location", label: "الموقع", minWidth: 50 },
	{
		id: "is_blocked",
		label: "محظور",
		minWidth: 170,
		format: (value: any) => (value ? "نعم" : "لا"),
	},
	{ id: "note", label: "ملاحظات", minWidth: 50 },
	{
		id: "user_permissions",
		label: "الصلاحيات",
		minWidth: 50,
		format: (value: any) =>
			value.map((item: any) => item.permission).join(", "),
	},
];

const Users = ({ user }) => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		if (!user.is_superuser) {
			setError("لا يمكنك الوصول إلى هذه الصفحة");
			setLoading(false);
		} else {
			setError("");
			const token = JSON.parse(localStorage.getItem("tokens"));
			if (!token) {
				navigate("/login");
			}
			const config = {
				method: "get",
				url: `account/get_all_users`,
			};
			axios(config)
				.then((response) => {
					setUsers(response.data);
					setLoading(false);
				})
				.catch((error) => {
					setError("حدث خطأ ما");
					setLoading(false);
				});
		}
	}, [navigate, user.is_superuser]);
	return (
		<>
			{!user.is_superuser ? (
				<h1>{error}</h1>
			) : (
				<Box>
					<Box>
						<Container>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
                                        marginBottom: "20px",
									}}
								>
									<AddUser id={null} setSelectedRowId={null} />
								</Box>
							</Box>
						</Container>
						<TableUsers
							data={users}
							columns={columns}
							loading={loading}
							error={error}
						/>
					</Box>
				</Box>
			)}
		</>
	);
};

export default Users;
