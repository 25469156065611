import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForward";
import GroupIcon from "@mui/icons-material/Group";
import Logo from "../imgs/sahad-logo1.png";
import Logo1 from "../imgs/sahad-logo3.png";
import { Container, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 240;

interface Props {
  children: React.ReactNode;
  window?: () => Window;
  user: any;
}

export default function ResponsiveDrawer(props: Props) {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const theme = useTheme();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const logout = () => {
    localStorage.removeItem("tokens");
    localStorage.removeItem("exp");
    navigate("/login");
    const config = {
      method: "post",
      url: "account/logout",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios(config);
  };

  const drawer = (
    <div>
      <Toolbar>
        <img
          alt="ShClinic"
          src={Logo}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Toolbar>
      <Divider />
      <List sx={{ color: "#bf3272" }}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemIcon>
              <EventSeatIcon sx={{ color: "#bf3272" }} />
            </ListItemIcon>
            <ListItemText primary="المواعيد" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/patients")}>
            <ListItemIcon>
              <AccessibleForwardIcon sx={{ color: "#bf3272" }} />
            </ListItemIcon>
            <ListItemText primary="المرضى" />
          </ListItemButton>
        </ListItem>
        {props.user.is_superuser ? (
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/users")}>
              <ListItemIcon>
                <GroupIcon sx={{ color: "#bf3272" }} />
              </ListItemIcon>
              <ListItemText primary="المستخدمين" />
            </ListItemButton>
          </ListItem>
        ) : null}
      </List>
      <Divider />
      <List sx={{ color: "#bf3272" }}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => logout()}>
            <ListItemIcon>
              <LogoutIcon sx={{ color: "#bf3272" }} />
            </ListItemIcon>
            <ListItemText primary="تسجيل الخروج" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "rgb(191 50 114)",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            عيادة اس اتش لطب وزراعة الاسنان والتجميل
          </Typography>
          <Container sx={{ display: { sm: "none" } }}>
            <img
              src={Logo1}
              alt="logo"
              style={{ width: "50px", height: "50px", margin: "0 10px" }}
            />
          </Container>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: "100%", // تغيير هنا
          [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
          },
          backgroundColor: "#f5f5f5",
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}
