import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AutoComplete1 from "../../components/AutoComplete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import GetDoctors from "../../components/GetDoctors";
import AppointmentsTable from "../../components/AppointmentsTable";
import AddAppointment from "../../components/AddAppointment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import {
	Button,
	Grid,
	useMediaQuery,
} from "@mui/material";

const Appointments1 = ({ user }) => {
	const [patient, setPatient] = useState("");
	const [search, setSearch] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [doctor, setDoctor] = useState("");
	const [appointments, setAppointments] = useState([]);
	const [isActive, setIsActive] = useState(true);
	const [filtersVisible, setFiltersVisible] = useState(false);
	const isMobile = useMediaQuery("(max-width:600px)");
	const isTablet = useMediaQuery("(max-width:960px)");
	const isDesktop = useMediaQuery("(min-width:960px)");

	const Navigate = useNavigate();

	const Android12Switch = styled(Switch)(({ theme }) => ({
		padding: 8,
		"& .MuiSwitch-track": {
			borderRadius: 22 / 2,
			"&::before, &::after": {
				content: '""',
				position: "absolute",
				top: "50%",
				transform: "translateY(-50%)",
				width: 16,
				height: 16,
			},
			"&::before": {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
					theme.palette.getContrastText(theme.palette.primary.main)
				)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
				left: 12,
			},
			"&::after": {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
					theme.palette.getContrastText(theme.palette.primary.main)
				)}" d="M19,13H5V11H19V13Z" /></svg>')`,
				right: 12,
			},
		},
		"& .MuiSwitch-thumb": {
			boxShadow: "none",
			width: 16,
			height: 16,
			margin: 2,
		},
	}));

	const handleError = (error) => {
		if (error.response && error.response.status === 401) {
			localStorage.removeItem("tokens");
			localStorage.removeItem("exp");
			Navigate("/login");
		}
		if (error.message === "Network Error") {
			localStorage.removeItem("tokens");
			localStorage.removeItem("exp");
			Navigate("/login");
		}
	};
	// useEffect(() => {
	// 	setIsMobileView(isMobile);
	// }, [isMobile]);

	useEffect(() => {
		const getAppointments = async () => {
			try {
				// Construct the URL parameters
				const urlSearchParams = new URLSearchParams();
				urlSearchParams.append("page", "1");
				urlSearchParams.append("count", "10");
				urlSearchParams.append("is_active", isActive);

				if (search) urlSearchParams.append("search", search);
				if (patient) urlSearchParams.append("patient_id", patient.id);
				if (startDate && endDate) {
					urlSearchParams.append("start_date", startDate);
					urlSearchParams.append("end_date", endDate);
				}
				if (user.is_superuser && doctor) {
					urlSearchParams.append("doctor_id", doctor.id);
				}

				const response = await axios.get(
					`clinic_controller/get_appointments?${urlSearchParams}`
				);
				setAppointments(response.data);
			} catch (error) {
				handleError(error);
			}
		};

		const token = JSON.parse(localStorage.getItem("tokens"));
		if (token) {
			getAppointments();
		} else {
			Navigate("/login");
		}
	}, [
		search,
		patient,
		startDate,
		endDate,
		doctor,
		isActive,
		user.is_superuser,
		Navigate,
	]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				padding: "20px",
			}}
		>
			<Box>
				<AddAppointment setPatient={setPatient} user={user} />
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "20px",
					borderRadius: "20px",
					border: "1px solid #ccc",
					padding: "20px",
					boxSizing: "border-box",
					backgroundColor: "#fff",
					boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
				}}
			>
				<Grid container spacing={2}>
					<Grid
						item
						xs={12}
						md={15}
						lg={20}
						sx={{
							display: isDesktop ? "flex" : isTablet ? "flex" : "",
							// justifyContent: "space-between",
							gap: isDesktop ? "50px" : "",
							flexWrap: isTablet && "wrap",
						}}
					>
						<Grid item xs={12} md={6} lg={4}>
							<TextField
								id="searchAppointments"
								label="بحث"
								variant="outlined"
								onChange={(e) => setSearch(e.target.value)}
								sx={{ width: "100%" , m:1 }}
							/>
						</Grid>
						{isMobile ? (
							<></>
						) : (
							<>
								<Grid item xs={12} md={5.5} sx={{width:"100%",m:1}}>
									<AutoComplete1 setPatient={setPatient} />
								</Grid>
								<Grid item xs={12} md={5.5}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											gap: "10px",
											alignItems: "center",
										}}
									>
										<label>من</label>
										<TextField
											id="start_date"
											type="date"
											variant="outlined"
											onChange={(e) => setStartDate(e.target.value)}
											sx={{width:"100%",
											m:1,}}
										/>
									</Box>
								</Grid>
								<Grid item xs={12} md={5.5}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											gap: "10px",
											alignItems: "center",
										}}
									>
										<label>الى</label>
										<TextField
											id="end_date"
											type="date"
											variant="outlined"
											onChange={(e) => setEndDate(e.target.value)}
											sx={{width:"100%",
											m:1,}}
										/>
									</Box>
								</Grid>
								<Grid item xs={12} md={5.5} sx={{width:"100%",
											m:1,}}>
									<GetDoctors setDoctor={setDoctor} />
								</Grid>
								<Grid item xs={12} md={5.5}>
									<FormControlLabel
										control={<Android12Switch defaultChecked={isActive} />}
										label="مفعل"
										onChange={(e) => setIsActive(e.target.checked)}
										sx={{width:"100%",
											m:1,}}
									/>
								</Grid>
							</>
						)}
						<Grid item xs={12} md={6}>
							{isMobile && filtersVisible && (
								<>
									<Grid item xs={12} md={6} sx={{ width: "100%" }}>
										<AutoComplete1 setPatient={setPatient} />
									</Grid>
									<Grid item xs={12} md={6} sx={{ width: "100%" }}>
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												gap: "10px",
												alignItems: "center",
											}}
										>
											<label>من</label>
											<TextField
												id="start_date"
												type="date"
												variant="outlined"
												onChange={(e) => setStartDate(e.target.value)}
												sx={{ width: "100%" }}
											/>
										</Box>
									</Grid>
									<Grid item xs={12} md={6}>
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												gap: "10px",
												alignItems: "center",
											}}
										>
											<label>الى</label>
											<TextField
												id="end_date"
												type="date"
												variant="outlined"
												onChange={(e) => setEndDate(e.target.value)}
												sx={{ width: "100%" }}
											/>
										</Box>
									</Grid>
									<Grid item xs={12} md={6} sx={{ width: "100%" }}>
										<GetDoctors setDoctor={setDoctor} />
									</Grid>
									<Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
										<FormControlLabel
											control={<Android12Switch defaultChecked={isActive} />}
											label="مفعل"
											onChange={(e) => setIsActive(e.target.checked)}
										/>
									</Grid>
								</>
							)}
							{isMobile && (
								<Button
									variant="contained"
									onClick={() => setFiltersVisible(!filtersVisible)}
									sx={{
										width: "100%",
										marginTop: "20px",
										backgroundColor: "#bf3272",
									}}
								>
									{filtersVisible ? "إخفاء الفلاتر" : "عرض الفلاتر"}
								</Button>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Box>

			<Box
				sx={{
					width: "100%",
					backgroundColor: "#fff",
					borderRadius: "20px",
					border: "1px solid #ccc",
					padding: "20px",
					marginTop: "20px",
					boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
				}}
			>
				{appointments?.data ? (
					<AppointmentsTable
						appointments={appointments}
						setAppointments={setAppointments}
					/>
				) : null}
			</Box>
		</Box>
	);
};

export default Appointments1;
