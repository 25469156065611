import React, { useEffect, useState } from "react";
import { TextField, Button, Box } from "@material-ui/core";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function LoginForm() {
  const Navigate = useNavigate();
  const { setError, setLoading, setUserId, setIsSuperUser } = useAuth();

  const tokens = localStorage.getItem("tokens");

  if (tokens) {
    Navigate("/");
  }

  const generateDeviceId = () => {
    const deviceId = localStorage.getItem("device_id");
    if (deviceId) return deviceId;
    const newDeviceId = Math.random().toString(36).substr(2, 9);
    localStorage.setItem(
      "device_id",
      "user-device-" + decodeURIComponent(newDeviceId)
    );
    Navigate(0)
    return newDeviceId;
  };

  useEffect(() => {
    if (!localStorage.getItem("device_id"))
      generateDeviceId();
  }, []);

  const [formData, setFormData] = useState({
    phone_number: "",
    password: "",
    device_id: localStorage.getItem("device_id"),
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      method: "post",
      url: `account/signin`,
      data: formData,
    };
    // remove default axios header auth
    delete axios.defaults.headers.common["Authorization"];
    axios(config)
      .then((res) => {
        let hours = 8 * 60 * 60 * 1000;
        const date = new Date();
        const expiration = new Date(date.getTime() + hours);
        localStorage.setItem("exp", expiration.getTime());
        localStorage.setItem("tokens", JSON.stringify(res.data.token));
        setUserId(res.data.account.id) 
        setIsSuperUser(res.data.account.is_superuser)
        Navigate("/");
      })
      .catch((err) => {
        console.error("Error during SignIn:", err);
        setError(err.message);
        setLoading(false);
      });
  };

  return (
    <Box>
      <form onSubmit={handleSubmit} style={{ padding: "10px" }}>
        <TextField
          style={{ display: "block", margin: "20px auto" }}
          id="phone_number"
          name="phone_number"
          type="text"
          placeholder="ادخل رقم الهاتف"
          value={formData.phone_number}
          onChange={handleChange}
        />
        <TextField
          style={{ display: "block", margin: "20px auto" }}
          id="password"
          name="password"
          type="password"
          placeholder="ادخل الرمز السري"
          value={formData.password}
          onChange={handleChange}
        />
        {/* <Box
          style={{ paddingBottom: "12px ", width: "100%", textAlign: "right" }}
        >
          <Link>نسيت الرمز السري</Link>
        </Box> */}

        <Button
          style={{
            width: "100%",
            display: "block",
            margin: "auto",
            backgroundColor: "rgb(191 50 114)",
            color: "#FFF",
          }}
          type="submit"
          variant="contained"
          fullwidth
        >
          تسجيل الدخول
        </Button>
      </form>
    </Box>
  );
}

export default LoginForm;
