import React from "react";
import TableRow from "@mui/material/TableRow";
import { Skeleton } from "@mui/material";
import TableCell from "@mui/material/TableCell";


const SkeletonTable = () => {
	return (
		<>
			<TableRow>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" height={50} />
				</TableCell>
			</TableRow>
		</>
	);
};

export default SkeletonTable;
