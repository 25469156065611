import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SkeletonTable from "./SkeletonTable.jsx";
import AddUser from "./AddUser.tsx";

export default function TableUsers({
	data,
	columns,
	loading,
	error,
}: {
	data: any;
	columns: any;
	loading: boolean;
	error: string;
}): JSX.Element {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const rows = data;
	const [selectedRowId, setSelectedRowId] = React.useState(null); // State to keep track of the selected row

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<Paper sx={{ width: "100%", overflow: "hidden" }}>
			<TableContainer>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									sx={{
										fontWeight: "900",
										fontSize: "17px",
										textAlign: "center",
									}}
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					{loading ? (
						<SkeletonTable />
					) : (
						<TableBody>
							{rows
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => {
									return (
										<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
											{columns.map((column) => {
												const value = row[column.id];
												return (
													<TableCell
														onClick={() => setSelectedRowId(row.id)}
														key={column.id}
														align={column.align}
														sx={{
															fontWeight: "600",
															fontSize: "13px",
															textAlign: "center",
														}}
													>
														{column.id === "is_active" ||
														column.id === "is_blocked"? value ? "نعم" : "لا" :
                                                        column.id === "user_permissions" ?
															  row.user_permissions.map((permission) => (
																	<span key={permission.id}>
																		{permission.permission}&{" "}
																	</span>
															  ))
															: column.format && typeof value === "number"
															? column.format(value)
															: value}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
						</TableBody>
					)}
				</Table>
			</TableContainer>
			{selectedRowId ? (
				<AddUser id={selectedRowId} setSelectedRowId={setSelectedRowId} />
			) : null}
			<TablePagination
				style={{ color: "#bf3272" }}
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={data.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage="عدد الصفوف في الصفحة:"
				labelDisplayedRows={({ from, to, count }) =>
					`${from}-${to} من ${count}`
				}
			/>
		</Paper>
	);
}
