import React from "react";
import {
	Box,
	Typography,
	Button,
	Snackbar,
	Dialog,
	Divider,
} from "@mui/material";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

const EditReport = ({
	id,
	report,
	setReport,
	setOpenReport,
	setOpenReportNote,
	openReport,
	openReportNote,
	setOpen,
	setMessage,
	setError,
	open,
	message,
	user,
}) => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "space-evenly",
				flexDirection: "column",
				marginTop: "20px",
				backgroundColor: "white",
				padding: "20px",
				borderRadius: "10px",
				boxShadow: "0px 0px 10px 0px #000000",
				width: "100%",
			}}
		>
			<Typography variant="h4" align="center">
				التشخيص
			</Typography>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-around",
					flexDirection: "row",
					flexWrap: "wrap",
					alignItems: "center",
					gap: 2,
					margin: 2,
					borderRadius: "10px",
					boxShadow: "0px 0px 10px 0px #000000",
				}}
			>
				<Box>
					<Typography variant="h5" align="center" sx={{ margin: 2 }}>
						الفحص
						{user.is_superuser ||
												(user.user_permissions &&
													user.user_permissions.some(
														(permission) => permission.permission === "دكتور"
													)) ? (
						<IconButton onClick={() => setOpenReport(true)}>
							<EditIcon />
						</IconButton>
					) : null}
					</Typography>
					<Divider />
					<Typography
						variant="h6"
						align="center"
						sx={{ margin: 2, whiteSpace: "pre-line" }}
					>
						{report ? report?.report : "لايوجد تقرير"}
					</Typography>
				</Box>
				<Box>
					<Typography variant="h5" align="center" sx={{ margin: 2 }}>
						الملاحظات
						{user.is_superuser ||
												(user.user_permissions &&
													user.user_permissions.some(
														(permission) => permission.permission === "دكتور"
													)) ? (
						<IconButton onClick={() => setOpenReportNote(true)}>
							<EditIcon />
						</IconButton>
					) : null}
					</Typography>
					<Divider />
					<Typography
						variant="h6"
						align="center"
						sx={{ margin: 2, whiteSpace: "pre-line" }}
					>
						{report ? report?.note : "لايوجد ملاحظات"}
					</Typography>
				</Box>
			</Box>
			<Dialog
				open={openReport}
				onClose={() => setOpenReport(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<Box sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					margin: 2,
					backgroundColor: "white",
					padding: 2,
					borderRadius: 2,
				}}>
					<Typography variant="h5" align="center" sx={{ margin: 2 }}>
						الفحص
					</Typography>
					<TextareaAutosize
						id="report"
						label="الفحص"
						variant="outlined"
						minRows={3}
						maxRows={5}
						fullwidth
						value={report?.report}
						onChange={(e) => setReport({ ...report, report: e.target.value })}
						sx={{ margin: 2 }}
					/>
					<Button
						variant="contained"
							sx={{
								width: "100%",
								backgroundColor: "#bf3272",
								"&:hover": {
									backgroundColor: "#d56195",
									color: "black",
								},
								marginBottom: "20px",
							}}
						onClick={() => {
							setOpenReport(false);
							const config = {
								method: "post",
								url: `clinic_controller/add_report?appointment_id=${id}`,
								headers: {
									"Content-Type": "application/json",
									Accept: "application/json",
								},
								data: JSON.stringify(report),
							};
							axios(config)
								.then((res) => {
									setOpen(true);
									setMessage("تم تعديل التقرير بنجاح");
								})
								.catch((err) => {
									console.error("Error during appointments:", err.message);
									setError(err.message);
								});
						}}
					>
						حفظ
					</Button>
				</Box>
			</Dialog>
			<Dialog
				open={openReportNote}
				onClose={() => setOpenReportNote(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				sx={{ display: "flex", justifyContent: "center" }}
			>
				<Box sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					margin: 2,
					backgroundColor: "white",
					padding: 2,
					borderRadius: 2,
				}}>
					<Typography variant="h5" align="center" sx={{ margin: 2 }}>
						الملاحظات
					</Typography>
					<TextareaAutosize
						id="note"
						label="الملاحظات"
						variant="outlined"
						minRows={3}
						maxRows={5}
						fullwidth
						value={report?.note}
						onChange={(e) => setReport({ ...report, note: e.target.value })}
						sx={{
							margin: 2,
							width: '100%', 
							minHeight: '100px', 
							padding: '10px', 
							borderRadius: '8px', 
							border: '1px solid #ccc', 
							fontSize: '16px', 
							fontFamily: 'inherit', 
							resize: 'none', 
							'&:focus': {
							  borderColor: 'primary.main', 
							  boxShadow: (theme) => `0 0 0 0.15rem ${theme.palette.primary.main}40`,
							},
						  }}
					/>
					<Button
						variant="contained"
						sx={{
							width: "100%",
							backgroundColor: "#bf3272",
							"&:hover": {
								backgroundColor: "#d56195",
								color: "black",
							},
							marginBottom: "20px",
						}}
						onClick={() => {
							setOpenReportNote(false);
							const config = {
								method: "post",
								url: `clinic_controller/add_report?appointment_id=${id}`,
								headers: {
									"Content-Type": "application/json",
									Accept: "application/json",
								},
								data: {
									report: report ? report.report : "لايوجد تقرير",
									note: report ? report.note : "لايوجد ملاحظات",
								},
							};
							axios(config)
								.then((res) => {
									setOpen(true);
									setMessage("تم تعديل التقرير بنجاح");
								})
								.catch((err) => {
									console.error("Error during appointments:", err.message);
									setError(err.message);
								});
						}}
					>
						حفظ
					</Button>
				</Box>
			</Dialog>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={() => setOpen(false)}
			>
				<Typography variant="h6">{message}</Typography>
			</Snackbar>
		</Box>
	);
};

export default EditReport;
