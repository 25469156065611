import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
	Box,
	Typography,
	TextField,
	Button,
	Dialog,
	Divider,
	Grid,
} from "@mui/material";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import BasicInfoEdit from "../components/BasicInfoEdit";
import EditReport from "../components/EditReport";
import AddIcon from "@mui/icons-material/Add";
import Medcine from "./Medcine";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const EditAppointment = ({ user }) => {
	const { id } = useParams();
	const [message, setMessage] = useState("");
	const [open1, setOpen1] = useState(false);
	const [prescription, setPrescription] = useState("");
	const [report, setReport] = useState({
		report: "",
		note: "",
	});
	const [date, setDate] = useState("");
	const [doctor, setDoctor] = useState("");
	const [patient, setPatient] = useState("");
	// const [pay, setPay] = useState("");
	const [status, setStatus] = useState(true);
	const [medcine, setMedcine] = useState();
	// const [name, setName] = useState("");
	const [note, setNote] = useState("");
	const [openDoctor, setOpenDoctor] = useState(false);
	const [openReport, setOpenReport] = useState(false);
	const [openReportNote, setOpenReportNote] = useState(false);
	const [openPrescription, setOpenPrescription] = useState(false);
	const [medcineNote, setMedcineNote] = useState("");
	const [medcineType, setMedcineType] = useState("");
	const [openMedcineEdit, setOpenMedcineEdit] = useState(false);
	const [prescriptionId, setPrescriptionId] = useState(0);
	const Navigate = useNavigate();

	useEffect(() => {
		const config = {
			method: "get",
			url: `clinic_controller/get_appointment_by_id?appointment_id=${id}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		axios(config)
			.then((res) => {
				// setAppointment(res.data);
				setPrescription(res.data.prescription);
				setDate(res.data.date);
				setDoctor(res.data.doctor);
				setPatient(res.data.patient);
				// setPay(res.data.pay);
				setStatus(res.data.is_active);
				setMedcine(res.data.medcine);
				// setName(res.data.name);
				setNote(res.data.note);
				if (res.data.report) {
					setReport(res.data.report);
				}
			})
			.catch((err) => {
				if (err?.response?.data?.message === "لايوجد موعد بهذا الرقم") {
					// setError("لايوجد موعد بهذا الرقم");
					Navigate("/");
				}
				// setError(err.message);
			});
	}, [id]);

	const handleDelete = (itemId) => {
		const config = {
			method: "delete",
			url: `clinic_controller/delete_prescription?appointment_id=${id}&prescription_id=${itemId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		axios(config)
			.then((res) => {
				setPrescription(prescription.filter((item) => item.id !== itemId));
			})
			.catch((err) => {
				// setError(err.message);
			});
	};


	return (
		<Box>
			<IconButton
				onClick={() => Navigate("/")}
				style={{
					marginBottom: "20px",
					marginTop: "20px",
					color: "#bf3272",
					float: "left",
				}}
			>
				رجوع
				<ArrowBackIosIcon />
			</IconButton>
			<BasicInfoEdit
				date={date}
				setDate={setDate}
				doctor={doctor}
				setDoctor={setDoctor}
				patient={patient}
				setPatient={setPatient}
				status={status}
				setStatus={setStatus}
				note={note}
				setNote={setNote}
				openDoctor={openDoctor}
				setOpenDoctor={setOpenDoctor}
				// setOpen={setOpen}
				setMessage={setMessage}
				// setError={setError}
				setOpen1={setOpen1}
				open1={open1}
				id={id}
				user={user}
			/>
			<EditReport
				report={report}
				setReport={setReport}
				openReport={openReport}
				setOpenReport={setOpenReport}
				openReportNote={openReportNote}
				setOpenReportNote={setOpenReportNote}
				message={message}
				setMessage={setMessage}
				// setError={setError}
				id={id}
				user={user}
			/>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					marginTop: "20px",
					backgroundColor: "white",
					borderRadius: "10px",
					padding: "20px",
					boxShadow: "0px 0px 10px 0px #000000",
					width: "100%",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography variant="h4" align="center">
						العلاجات
						{user.is_superuser ||
						(user.user_permissions &&
							user.user_permissions.some(
								(permission) => permission.permission === "دكتور"
							)) ? (
							<IconButton
								onClick={() => {
									setOpenPrescription(true);
									setMedcineNote("");
									setMedcineType("");
								}}
							>
								<AddIcon />
							</IconButton>
						) : null}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-evenly",
						alignItems: "center",
						flexDirection: "column",
						marginTop: "20px",
						backgroundColor: "white",
						borderRadius: "10px",
						padding: "20px",
						overflowx: "auto",
						width: "100%",
						maxWidth: "1000px",
					}}
				>
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						direction="column"
						marginTop="20px"
						bgcolor="white"
						borderRadius="10px"
						padding="20px"
						overflowx="auto"
					>
						<table className="custom-table">
							<thead>
							<tr>
								<th>
									<Typography variant="h6" align="center">
										الدواء
									</Typography>
									<Divider />
								</th>
								<th>
									<Typography variant="h6" align="center">
										النوع
									</Typography>
									<Divider />
								</th>
								<th>
									<Typography variant="h6" align="center">
										ملاحظات
									</Typography>
									<Divider />
								</th>
							</tr>
							</thead>
							<tbody>
							{prescription
								? prescription.map((item, index) => (
										<tr key={index}>
											<td>
												<Typography variant="h6" align="center">
													{item.medicine.name} ({item.medicine.price})
												</Typography>
												<Divider />
											</td>
											<td>
												<Typography variant="h6" align="center">
													{item.type}
												</Typography>
												<Divider />
											</td>
											<td>
												<Typography variant="h6" align="center">
													{item.note}
												</Typography>
												<Divider />
											</td>
											<td
												style={{
													width: "97px",
													textAlign: "right",
													border: "none",
												}}
											>
												{user.is_superuser ||
												(user.user_permissions &&
													user.user_permissions.some(
														(permission) => permission.permission === "دكتور"
													)) ? (
													<>
														<IconButton onClick={() => {
																setOpenMedcineEdit(true);
																setPrescriptionId(item.id);
																setMedcineNote(item.note);
																setMedcineType(item.type);
																setMedcine(item.medicine);
															}}>
															<EditIcon />
														</IconButton>
														<IconButton onClick={() => handleDelete(item.id)}>
															<DeleteIcon />
														</IconButton>
													</>
												) : null}
											</td>
										</tr>
								  ))
								: null}
							</tbody>
						</table>
					</Grid>
				</Box>
				<Dialog
					open={openPrescription}
					onClose={() => setOpenPrescription(false)}
					sx={{ width: "100%" }}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							marginTop: "20px",
							backgroundColor: "white",
							borderRadius: "10px",
							padding: "20px",
							width: "100%",
						}}
					>
						<Medcine
							setOpenPrescription={setOpenPrescription}
							setPrescription={setPrescription}
							setMedcine={setMedcine}
						/>
						<TextField
							value={medcineNote}
							onChange={(e) => setMedcineNote(e.target.value)}
							label="ملاحظات"
							sx={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
						/>
						<TextField
							value={medcineType}
							onChange={(e) => setMedcineType(e.target.value)}
							label="النوع"
							sx={{ width: "100%", marginBottom: "20px" }}
						/>
						<Button
							variant="contained"
							sx={{
								width: "100%",
								backgroundColor: "#bf3272",
								"&:hover": {
									backgroundColor: "#d56195",
									color: "black",
								},
								marginBottom: "20px",
							}}
							onClick={() => {
								const config = {
									method: "post",
									url: `clinic_controller/add_prescription?appointment_id=${id}`,
									headers: {
										"Content-Type": "application/json",
										Accept: "application/json",
									},
									data: {
										data: [[medcine.id, medcineNote, medcineType]],
									},
								};
								axios(config)
									.then((res) => {
										Navigate(0);
									})
									.catch((err) => {
										// setError(err.message);
									});
								setOpenPrescription(false);
							}}
						>
							حفظ
						</Button>
					</Box>
				</Dialog>
				<Dialog
					open={openMedcineEdit}
					onClose={() => setOpenMedcineEdit(false)}
					sx={{ width: "100%" }}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							marginTop: "20px",
							backgroundColor: "white",
							borderRadius: "10px",
							padding: "20px",
							width: "100%",
						}}
					>
						<Typography
							variant="h4"
							align="center"
							sx={{ marginBottom: "20px" }}
						>
							تعديل الدواء
						</Typography>
						<Medcine
							setOpenPrescription={setOpenPrescription}
							setPrescription={setPrescription}
							setMedcine={setMedcine}
						/>
						<TextField
							value={medcineNote}
							onChange={(e) => setMedcineNote(e.target.value)}
							label="ملاحظات"
							sx={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
						/>
						<TextField
							value={medcineType}
							onChange={(e) => setMedcineType(e.target.value)}
							label="النوع"
							sx={{ width: "100%", marginBottom: "20px" }}
						/>
						<Button
							variant="contained"
							sx={{
								width: "100%",
								backgroundColor: "#bf3272",
								"&:hover": {
									backgroundColor: "#d56195",
									color: "black",
								},
								marginBottom: "20px",
							}}
							onClick={() => {
								const config = {
									method: "put",
									url: `clinic_controller/edit_prescription?appointment_id=${id}&prescription_id=${prescriptionId}`,
									headers: {
										"Content-Type": "application/json",
										Accept: "application/json",
									},
									data: {
										medicine_id: medcine.id,
										note: medcineNote,
										type: medcineType,
									},
								};
								axios(config)
									.then((res) => {
										Navigate(0);
									})
									.catch((err) => {
										// setError(err.message);
									});
								setOpenMedcineEdit(false);
							}}
						>
							حفظ
						</Button>
					</Box>
				</Dialog>
			</Box>
		</Box>
	);
};

export default EditAppointment;
