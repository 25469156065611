// Import necessary dependencies
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

interface AddPatientProps {
	id: number | null;
	setSelectedRowId: Function | null;
}

interface FadeProps {
	children: React.ReactElement;
	in?: boolean;
	onClick?: any;
	onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
	onExited?: (node: HTMLElement, isAppearing: boolean) => void;
	ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>((props, ref) => {
	const {
		children,
		in: open,
		onClick,
		onEnter,
		onExited,
		ownerState,
		...other
	} = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter(null as any, true);
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited(null as any, true);
			}
		},
	});

	return (
		<animated.div ref={ref} style={style} {...other}>
			{React.cloneElement(children, { onClick })}
		</animated.div>
	);
});

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 300,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
	textAlign: "center" as "center",
};

export default function AddUser({ id, setSelectedRowId }: AddPatientProps) {
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState("");
	const [full_name, setFullName] = React.useState("");
	const [phone_number, setPhoneNumber] = React.useState("");
	const [location, setLocation] = React.useState("");
	const [is_blocked, setIsBlocked] = React.useState(false);
	const [note, setNote] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [user_permissions, setUserPermissions] = React.useState([]);
	const [permissions, setPermissions] = React.useState([]);

	const navigate = useNavigate();

	React.useEffect(() => {
		if (id) {
            let config = {
                method: "get",
                url: `account/get_all_permissions`,
            };
            axios(config)
                .then((response) => {
                    setPermissions(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    setError("حدث خطأ ما");
                    setLoading(false);
                });
			config = {
				method: "get",
				url: `account/get_user_by_id?id=${id}`,
			};
			axios(config)
				.then((response) => {
					setFullName(response.data.full_name);
					setPhoneNumber(response.data.phone_number);
					setLocation(response.data.location);
					setIsBlocked(response.data.is_blocked);
					setNote(response.data.note);
					setUserPermissions(
						response.data.user_permissions.map((permission) => permission.id)
					);
					setLoading(false);
				})
				.catch((error) => {
					setError("حدث خطأ ما");
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
        
		
	}, [id]);

	const handleOpen = () => {setOpen(true)
        const config = {
			method: "get",
			url: `account/get_all_permissions`,
		};
		axios(config)
			.then((response) => {
				setPermissions(response.data);
				setLoading(false);
			})
			.catch((error) => {
				setError("حدث خطأ ما");
				setLoading(false);
			});
    };
	const handleClose = () => {
		setOpen(false);
		if (setSelectedRowId) setSelectedRowId(null);
	};
	const handleAdd = () => {
		let url = "";
		let method = "";
		if (id) {
            if (password !== "") {
                const config = {
                    method: "put",
                    url: `account/change_password_by_id?id=${id}`,
                    data: {
                        new_password1: password,
                        new_password2: password,
                    },
                };
                axios(config)
                    .catch((error) => {
                        setError("حدث خطأ ما");
                    });
            }
			url = `account/update_user?id=${id}`;
			method = "put";
		} else {
			url = "account/create_user";
			method = "post";
		}
		const config = {
			method: method,
			url: url,
			data: {
				full_name: full_name,
				phone_number: phone_number,
				password1: password,
				password2: password,
				permissions: user_permissions,
				location: location,
				note: note,
				is_blocked: is_blocked,
			},
		};
		axios(config)
			.then((response) => {
				setLoading(false);
				handleClose();
				navigate(0);
			})
			.catch((error) => {
				setError("حدث خطأ ما");
				setLoading(false);
			});
	};

	return (
		<div>
			{!id ? (
				<Button
					variant="contained"
					onClick={handleOpen}
					sx={{
						borderRadius: "100%",
						width: "64px",
						height: "64px",
						color: "#fff",
						backgroundColor: "#bf3272",
						"&:hover": {
							backgroundColor: "#d56195",
							color: "black",
						},
					}}
				>
					<AddIcon />
				</Button>
			) : (
				setTimeout(() => {
					setOpen(true);
				}, 1)
			)}

			<Modal
				aria-labelledby="spring-modal-title"
				aria-describedby="spring-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						TransitionComponent: Fade,
					},
				}}
			>
				<Fade in={open}>
					{loading ? (
						<h1>loading</h1>
					) : (
						<Box sx={style}>
							{error && <h1>{error}</h1>}
							<Typography id="spring-modal-title" variant="h6" component="h2">
								{id ? "تعديل بيانات مستخدم" : "إضافة مستخدم"}
							</Typography>
							<Box
								component="form"
								sx={{
									"& > :not(style)": { m: 1, width: "25ch" },
								}}
								noValidate
								autoComplete="off"
							>
								<TextField
									id="standard-basic"
									label="الاسم"
									value={full_name}
									onChange={(e) => setFullName(e.target.value)}
								/>
								<TextField
									id="standard-basic"
									label="رقم الهاتف"
									value={phone_number}
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>
								<TextField
									id="standard-basic"
									label="الموقع"
									value={location}
									onChange={(e) => setLocation(e.target.value)}
								/>
								<TextField
									id="standard-basic"
									label="الملاحظات"
									value={note}
									onChange={(e) => setNote(e.target.value)}
								/>
								<TextField
									id="standard-basic"
									label="كلمة المرور"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={is_blocked}
											onChange={(e) => setIsBlocked(e.target.checked)}
										/>
									}
									label="حظر"
								/>
								<Typography id="spring-modal-title" variant="h6" component="h2">
									الصلاحيات
								</Typography>
								{permissions.map((permission) => (
									<FormControlLabel
										control={
											<Checkbox
												checked={user_permissions.includes(permission.id)}
												onChange={(e) => {
													const checkedPermissionId = permission.id;

													setUserPermissions((prevPermissions) => {
														if (e.target.checked) {
															return [...prevPermissions, checkedPermissionId];
														} else {
															return prevPermissions.filter(
																(item) => item !== checkedPermissionId
															);
														}
													});
												}}
											/>
										}
										label={permission.permission}
									/>
								))}
								<Button
                                onClick={handleAdd}
                                variant="contained"
                                sx={{ backgroundColor: "#bf3272", color: "#fff", margin: "10px" }}
                            >
                                {id ? "تعديل" : "اضافة"}
                            </Button>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                sx={{ backgroundColor: "#bf3272", color: "#fff", margin: "10px" }}
                            >
                                الغاء
                            </Button>
							</Box>
						</Box>
					)}
				</Fade>
			</Modal>
		</div>
	);
}
