import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  Dialog,
  Container,
  Divider,
  Grid,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import AutoComplete1 from "../components/AutoComplete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import GetDoctors from "../components/GetDoctors";

const BasicInfoEdit = ({
  id,
  patient,
  setPatient,
  doctor,
  setDoctor,
  date,
  setDate,
  note,
  setNote,
  status,
  setStatus,
  setError,
  setMessage,
  setOpen,
  open,
  setOpen1,
  open1,
  setOpenDoctor,
  openDoctor,
  message,
  user,
}) => {
  const saveAppointmentInfo = () => {
    const config = {
      method: "put",
      url: `clinic_controller/edit_appointment?appointment_id=${id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: {
        date: date,
        doctor: doctor.id,
        patient: patient.id,
        is_active: status,
        note: note,
      },
    };
    axios(config)
      .then((res) => {
        setMessage("تم تعديل الموعد بنجاح");
        setOpen(true);
      })
      .catch((err) => {
        setError(err.message);
        setOpen(true);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "space-evenly",
        flexDirection: "column",
        marginTop: "20px",
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "0px 0px 10px 0px #000000",
        width: "100%",
      }}
    >
      <Typography variant="h4" align="center">
        معلومات الموعد الاساسية
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Container>
            <Box>
              <Typography variant="h6">اسم المريض</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "fit-content",
                }}
              >
                <Typography variant="h6">{patient.name}</Typography>
                <IconButton onClick={() => setOpen1(true)}>
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
          </Container>
          <Dialog open={open1} onClose={() => setOpen1(false)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                m: 2,
                backgroundColor: "white",
              }}
            >
              <AutoComplete1 setPatient={setPatient} />
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  backgroundColor: "#bf3272",
                  "&:hover": {
                    backgroundColor: "#d56195",
                    color: "black",
                  },
                  marginBottom: "20px",
                }}
                onClick={() => setOpen1(false)}
              >
                حفظ
              </Button>
            </Box>
          </Dialog>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              alignItems: "center",
              m: 2,
            }}
          >
            <Typography variant="h6" mb={1}>
              الموعد
            </Typography>
            <TextField
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Container>
            <Box>
              <Typography variant="h6">الطبيب</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "fit-content",
                }}
              >
                <Typography variant="h6">{doctor.full_name}</Typography>
                {user.is_superuser ||
                (user.user_permissions &&
                  user.user_permissions.some(
                    (permission) => permission.permission === "سكرتير"
                  )) ? (
                  <IconButton onClick={() => setOpenDoctor(true)}>
                    <EditIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Container>
          <Dialog open={openDoctor} onClose={() => setOpenDoctor(false)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                m: 2,
                backgroundColor: "white",
              }}
            >
              <GetDoctors setDoctor={setDoctor} />
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  backgroundColor: "#bf3272",
                  "&:hover": {
                    backgroundColor: "#d56195",
                    color: "black",
                  },
                  marginBottom: "20px",
                }}
                onClick={() => setOpenDoctor(false)}
              >
                حفظ
              </Button>
            </Box>
          </Dialog>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="ملاحظات"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            multiline
            sx={{ margin: 2 }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            sx={{ margin: 2 }}
            control={
              <Checkbox
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                name="checkedA"
              />
            }
            label="غير منتهي"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#bf3272",
              "&:hover": {
                backgroundColor: "#d56195",
                color: "black",
              },
              marginBottom: "20px",
              margin: 2,
            }}
            onClick={saveAppointmentInfo}
          >
            حفظ
          </Button>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={() => setOpen(false)}
            message={message}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicInfoEdit;
