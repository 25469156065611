import ImageComponent from "../../components/register/ImageComponent";
import LoginForm from "../../components/register/LoginForm";
import { Box } from "@mui/system";
import "./Login.css";

function Login() {
	return (
		<Box
			className="image"
			style={{
				display: "flex",
				flexDirection: "column",
				height: "100vh",
				justifyContent: "center",
				alignItems: "center",
				zIndex: 1000,
			}}
		>
			<ImageComponent />
			<Box
				sx={{
					boxShadow:
						"rgb(191 50 114 / 50%) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
					backgroundColor: "rgba(255 , 255 , 255 , 0.2)",
					width: 400,
					height: "400px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					marginTop: "111px",
				}}
				className="boxContainer"
			>
				<LoginForm />
			</Box>
		</Box>
	);
}

export default Login;
