import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { Box, Dialog, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';


export default function Medcine({setMedcine}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [openMedcine, setOpenMedcine] = React.useState(false);
  const loading = open && options.length === 0;
  const [medcineName, setMedcineName] = React.useState("");
  const [price, setPrice] = React.useState("");

  React.useEffect(() => {

    if (!loading) {
      return undefined;
    }
    const config = {
        methode : 'get',
        url : 'clinic_controller/get_medicines?page=1&count=100000',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    axios(config)
    .then((response) => {
        if (response.data.data) {
        setOptions(response.data.data);
        }
    })
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


    const handleAdd = () => {
        
        const config = {
            method: 'post',
            url: 'clinic_controller/add_medicine',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data: {
                name: medcineName,
                price: price
            }
        };
        axios(config)
        .then((response) => {
            setOpenMedcine(false);
        })
        .catch((error) => {
        });

    }
    
  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {setMedcine(value)} }
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      getOptionLabel={(option) => option?.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            width: 300
        }}>
        <TextField
          {...params}
          label="الدواء"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
        <IconButton onClick={() => setOpenMedcine(true)}>
					<AddIcon />
				</IconButton>
        <Dialog
        open={openMedcine}
        onClose={() => setOpenMedcine(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                m: 2,
                backgroundColor: "white",
            }}
        >
            <Typography variant="h6">اضافة دواء</Typography>
            <TextField
                id="name"
                label="اسم الدواء"
                variant="outlined"
                sx={{ margin: 2 }}
                value={medcineName}
                onChange={(e) => setMedcineName(e.target.value)}
            />
            <TextField
                id="price"
                label="السعر"
                variant="outlined"
                sx={{ margin: 2 }}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
            />
            </Box>
            <Button onClick={handleAdd}>حفظ</Button>
        </Dialog>


        </Box>
      )}
    />
  );
}

