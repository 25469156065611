
import { Box, Container, TextField } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
// eslint-disable-next-line
import AddPatient from "../components/AddPatient.tsx";
import TablePatients from "../components/TablePatients.tsx";

interface Column {
	id: "id" | "name" | "age" | "phone" | "address" | "date_created";
	label: string;
	minWidth?: number;
	align?: "right";
	format?: (value: number) => string;
}

const columns: readonly Column[] = [
	{ id: "id", label: "التسلسل", minWidth: 50 },
	{ id: "name", label: "الاسم", minWidth: 170 },
	{ id: "age", label: "العمر", minWidth: 50 },
	{ id: "phone", label: "الهاتف", minWidth: 120 },
	{ id: "address", label: "العنوان", minWidth: 170 },
	{ id: "date_created", label: "تاريخ الانشاء", minWidth: 50 },
];

const Patients = ({ user }) => {
	const [patient, setPatient] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(10);
	const navigate = useNavigate();

	useEffect(() => {
		const token = JSON.parse(localStorage.getItem("tokens"));
		if (!token) {
			navigate("/login");
		}
		let urlSearch = "";
		if (search !== "" && search !== null) {
			urlSearch = `&search=${search}`;
		}

		const config = {
			method: "get",
			url: `clinic_controller/get_patients?page=${page}&count=${count}${urlSearch}`,
			headers: {
				Authorization: `Bearer ${token.accessToken}`,
			},
		};
		axios(config)
			.then((response) => {
				setPatient(response.data);
				setLoading(false);
			})
			.catch((error) => {
				setError("حدث خطأ ما");
				setLoading(false);
			});
	}, [search, navigate, page, count]);
	return (
		<Box>
			<Box>
				<Container>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<AddPatient id={null} setSelectedRowId={null} />
						</Box>
						<Box component="form" noValidate sx={{ width: "100%" }}>
							<TextField
								margin="normal"
								id="search"
								label="بحث"
								name="search"
								autoFocus
								onChange={(e) => setSearch(e.target.value)}
								sx={{ backgroundColor: "#fff", borderRadius: "5px" }}
								fullWidth
							/>
						</Box>
					</Box>
				</Container>
				<TablePatients
					data={patient}
					setPage1={setPage}
					setCount={setCount}
					columns={columns}
					loading={loading}
					error={error}
				/>
			</Box>
		</Box>
	);
};

export default Patients;
